import PageContext from '@context'
import getTaxNotice from '@helpers/get-tax-notice'
import useStringTranslation from '@hooks/use-string-translation'
import Box from '@material-ui/core/Box'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import makeStyles from '@material-ui/styles/makeStyles'
import renderBlok from '@renderBlok'
import renderBloks from '@renderBloks'
import { Accordion, TextSm } from '@system'
import ContentContainer from '@system/content-container'
import PageContainer from '@system/page-container'
import classNames from 'classnames'
import get from 'lodash/get'
import React, { useContext, useState } from 'react'
import SbEditable from 'storyblok-react'

const priceFontSizeCoeffs = {
  amer: 0.79,
  ca: 0.79,
  ch: 0.79,
  cz: 0.92,
  dk: 0.69,
  hu: 0.72,
  in: 0.85,
  jp: 0.85,
  kr: 0.69,
  mx: 0.69,
  my: 0.79,
  no: 0.79,
  pl: 0.79,
  se: 0.79,
  sg: 0.69,
}

const useStyles = makeStyles((theme) => ({
  withFeaturedTag: {
    marginTop: '3rem',
    [theme.breakpoints.down('xs')]: {
      marginTop: '1rem',
    },
  },
  legacyContainer: {
    padding: '40px 0',
  },
  pricingCardsModuleRoot: ({ isLegacyLayout, isThreeColumnLayout }) => ({
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      maxWidth: isLegacyLayout || isThreeColumnLayout ? null : '448px',
      padding: isLegacyLayout || isThreeColumnLayout ? null : '0 16px',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
    },
  }),
  pricingCardsModuleHeading: {
    color: theme.palette.primary.main,
    flex: 'none',
    fontSize: '30px',
    fontWeight: theme.typography.fontWeightBold,
    margin: '0 0 16px 24px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  cardContainer: ({ isLegacyLayout, verticalLayout }) => ({
    display: 'flex',
    flex: '1',
    flexDirection: verticalLayout ? 'column' : 'row',
    flexWrap: 'wrap',
    gap: '8px',
    justifyContent: isLegacyLayout ? 'center' : null,
    padding: isLegacyLayout ? '40px 0' : null,
    '& > *': {
      flex: isLegacyLayout ? 'none !important' : null,
      width: isLegacyLayout ? '258px !important' : null,
    },
  }),
  toggleContainer: {
    alignItems: 'center',
    backgroundColor: '#DDDDDD',
    border: '1px solid #C4C4C4',
    display: 'flex',
    flex: 'none',
    margin: '0 auto 0px auto',
    padding: '4px',
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '600px',
    },
  },
  toggleGroup: {
    display: 'flex',
    gap: '6px',
    width: '100%',
  },
  toggleButton: {
    border: 'none',
    color: '#000',
    borderRadius: '5px !important',
    flex: '1',
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightRegular,
    height: '30px',
    textTransform: 'capitalize',
    '&.Mui-selected': {
      backgroundColor: theme.palette.background.slate,
      color: '#fff',
      fontWeight: theme.typography.fontWeightBold,
      '&:hover': {
        backgroundColor: theme.palette.background.slate,
      },
    },
  },
  taxText: {
    width: '100%',
    fontSize: '11px',
    textAlign: 'center',
    color: theme.palette.text.tertiary,
    marginTop: '16px',
  },
  trialText: {
    width: '100%',
    fontSize: '16px',
    textAlign: 'center',
    color: theme.palette.text.tertiary,
    marginTop: '16px',
  },
  trialLink: {
    width: '100%',
    fontWeight: 700,
    '& :hover': {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    '& a ': {
      textTransform: 'none',
      color: theme.palette.primary.main,
      borderBottom: 'none',
    },
  },
}))

const PricingCardsModule = ({ brand, blok }) => {
  const context = useContext(PageContext)
  const [annualBillingStatus, setAnnualBillingStatus] = useState(1)
  const [hasFeaturedTag, setHasFeaturedTag] = useState(false)
  const billing = [false, true]

  const {
    pricingCards,
    trialText,
    trialUrl,
    topHeight,
    verticalLayout,
    hideBillingSwitch,
    hideAnnualizedPricing,
    variant,
    mobilePreviewText,
    heading,
    image,
  } = blok
  const locale = context.locale
  const getPricingCard = get(pricingCards, '[0]', {})
  const hasLargePrice = pricingCards?.length === 1 && variant === 'card'
  const isLegacyLayout = getPricingCard.variant === 'price'
  const isThreeColumnLayout = variant === 'card' && pricingCards?.length === 3
  const taxText = getTaxNotice(locale)

  const classes = useStyles({
    brand,
    isLegacyLayout,
    isThreeColumnLayout,
    verticalLayout,
  })

  const handleFeaturedTag = (boolean) => {
    setHasFeaturedTag(boolean)
  }

  const handleAlignment = (event, newAlignment) => {
    event.preventDefault()
    if (newAlignment !== null) setAnnualBillingStatus(newAlignment)
  }

  const BillingToggle = () => (
    <ToggleButtonGroup
      className={classes.toggleGroup}
      exclusive
      onChange={handleAlignment}
      value={annualBillingStatus}
    >
      <ToggleButton className={classes.toggleButton} value={0}>
        {useStringTranslation('Monthly')}
      </ToggleButton>
      <ToggleButton className={classes.toggleButton} value={1}>
        {useStringTranslation('Annually')}
      </ToggleButton>
    </ToggleButtonGroup>
  )

  const renderContent = () => {
    return (
      <>
        {!hideBillingSwitch ? (
          <div
            className={[classes.toggleContainer, 'pricing-toggle'].join(' ')}
          >
            <BillingToggle />
          </div>
        ) : null}
        <Box
          className={classNames(classes.cardContainer, {
            [classes.withFeaturedTag]: hasFeaturedTag,
          })}
        >
          {pricingCards
            ? pricingCards.map((card, cardIndex) =>
                renderBlok(card, {
                  annualBilling: billing[annualBillingStatus],
                  handleFeaturedTag,
                  hasLargePrice,
                  hasTaxText: !!taxText,
                  hideAnnualizedPricing,
                  locale,
                  priceFontSizeCoeff: priceFontSizeCoeffs[locale] || 1,
                  pricingCardsModuleHeading: heading,
                  pricingCardsModuleVariant: variant,
                  image: variant !== 'row' && cardIndex === 0 ? image : null,
                  topHeight,
                })
              )
            : null}
        </Box>
        {!!taxText && !hideBillingSwitch ? (
          <TextSm className={classes.taxText}>{`*${taxText}`}</TextSm>
        ) : null}
        {trialText ? (
          <TextSm className={classes.trialText}>
            {`${trialText} `}
            <span className={classes.trialLink}>{renderBloks(trialUrl)}</span>
          </TextSm>
        ) : null}
      </>
    )
  }

  if (isLegacyLayout) {
    return (
      <SbEditable content={blok}>
        <PageContainer className={classes.legacyContainer}>
          <ContentContainer>{renderContent()}</ContentContainer>
        </PageContainer>
      </SbEditable>
    )
  }

  return (
    <SbEditable content={blok}>
      <div className={classes.pricingCardsModuleRoot}>
        {heading ? (
          <div className={classes.pricingCardsModuleHeading}>{heading}</div>
        ) : null}
        <Accordion
          previewText={mobilePreviewText}
          pricingCardsModuleHeading={heading}
        >
          {renderContent()}
        </Accordion>
      </div>
    </SbEditable>
  )
}

export default PricingCardsModule
