const taxData = {
  apac: '',
  emea: '',
  amer: '',
  eu: 'Includes estimated VAT',
  ae: 'Excludes VAT',
  at: 'Inkl. geschätzte MwSt.',
  au: 'Excludes GST',
  be: 'Inclusief geschatte btw',
  ca: '',
  ch: 'Inkl. geschätzte MwSt.',
  cz: 'Včetně odhadované DPH',
  de: 'Inkl. geschätzte MwSt.',
  dk: 'Inkluderer anslået moms',
  es: 'Incluye el IVA estimado',
  fi: 'Sisältää arvioidun ALV:n',
  fr: 'TVA estimée incluse',
  gb: 'Includes estimated VAT',
  hu: 'Tartalmazza a becsült áfát',
  ie: 'Includes estimated VAT',
  in: 'Includes tax',
  it: 'IVA stimata inclusa',
  jp: '税込',
  kr: '세금 포함',
  mx: 'No incluye IVA',
  my: 'Includes 6% DST',
  nl: 'Inclusief geschatte btw',
  no: 'Inkluderer beregnet mva',
  nz: 'Excludes GST',
  pl: 'Uwzględnia szacunkową wartość podatku VAT',
  pt: 'IVA estimado incluído',
  se: 'Inkluderar moms',
  sg: 'Includes GST',
  us: '',
}

const getTaxNotice = (locale) => {
  return taxData[locale] || ''
}

export default getTaxNotice
